import { Spacer } from "@nextui-org/react";
import { GetServerSideProps } from "next";
import Head from "next/head";
import Background from "../components/background";
import Downloads from "../components/downloads";
import NumberCards from "../components/numberCards";
import { PlatformContext } from "../components/platform/context";
import Screen from "../components/screen";
import ScreenshotsCarousel from "../components/screenshotsCarousel";
import { LinkPrefixContext } from "../components/prefix";
import { Stats } from "../components/stats";
import { getStatsProps } from "../components/stats/props";
import { IntlProvider } from "react-intl";
import {
  isLanguage,
  LANGUAGES,
  loadLanguages,
  loadTranslation,
  pickLangFromHeaders,
  TranslationInfo,
} from "../components/language";
import Faq from "../components/faq";
import Support from "../components/support";
import { getHost, getProto } from "../components/contextUtils";
import SnackbarProvider from "react-simple-snackbar";
import { useEffect, useState } from "react";
import { Platform } from "../components/platform";
import getPlatform from "../components/platform/props";
import type { SoftwareApplication, WithContext } from "schema-dts";
import { isPostTL } from "../components/tllegacy";
import { DmcaCard } from "../components/dmcaCard";
import Footer from "../components/footer";
import { DmcaPageContent } from "./dmca";

const ldJson: WithContext<SoftwareApplication> = {
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  name: "Legacy Launcher",
  operatingSystem: "Windows 7, 10, 11 | Linux | macOS",
  applicationCategory: "GameApplication",
  offers: {
    "@type": "Offer",
    price: "0",
    priceCurrency: "USD",
  },
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "4.3",
    ratingCount: "88626",
  },
};

type Props = {
  stats: Stats | null;
  translation: TranslationInfo;
  title: string;
  description: string;
  host: string;
  proto: string;
  canonicalUrl: string;
  linkPrefix: string;
  dmca: boolean;
};

export default function Home({
  title,
  description,
  stats,
  linkPrefix,
  translation,
  host,
  proto,
  canonicalUrl,
  dmca,
}: Props) {
  const [platform, setPlatform] = useState<Platform | undefined>(undefined);
  const [isPostponedDmca, setPostponedDmca] = useState<boolean>(false);
  useEffect(() => {
    setPlatform(getPlatform(window.navigator.userAgent));
    setPostponedDmca(dmca);
  }, []);
  return (
    <IntlProvider
      locale={translation.lang}
      defaultLocale="en"
      messages={translation.messages ?? undefined}
    >
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${proto}://${host}/logo.png`} />
        <meta property="og:url" content={`${proto}://${host}`} />
        <meta property="og:description" content={description} />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${proto}://${host}`}
        />
        {LANGUAGES.map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${proto}://${host}/${lang}`}
          />
        ))}
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={description} key="desc" />
        <script
          key="schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...ldJson,
            }),
          }}
        />
      </Head>
      <SnackbarProvider>
        <PlatformContext.Provider value={platform}>
          <LinkPrefixContext.Provider value={linkPrefix}>
            {isPostponedDmca ? (
              <DmcaPageContent />
            ) : (
              <>
                <Background />
                <Screen dmca={dmca} />
                {!dmca && stats ? <NumberCards stats={stats} /> : undefined}
                {dmca ? <DmcaCard /> : undefined}
                <ScreenshotsCarousel />
                <Downloads />
                <Faq />
                <Support />
                <Footer />
              </>
            )}
          </LinkPrefixContext.Provider>
        </PlatformContext.Provider>
      </SnackbarProvider>
    </IntlProvider>
  );
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  const enableCaching = () => {
    context.res.setHeader(
      "Cache-Control",
      "public, s-maxage=900, stale-while-revalidate=900, stale-if-error=86400"
    );
  };
  if (context.locale === "default") {
    if (isLanguage(context.query["lang"])) {
      enableCaching();
      return {
        redirect: {
          destination: `/${context.query["lang"]}`,
          permanent: true,
        },
      };
    }
    let selectedLanguage = pickLangFromHeaders(context.req.headers);
    return {
      redirect: {
        destination: `/${selectedLanguage}`,
        permanent: false,
      },
    };
  } else if (context.locale == "en") {
    if (
      pickLangFromHeaders(context.req.headers) === "ru" &&
      (context.req.headers.referer?.startsWith(
        "https://yandex.ru/clck/jsredir"
      ) ||
        context.query["ysclid"])
    ) {
      // omg yandex wtf
      return {
        redirect: {
          destination: "/ru",
          permanent: false,
        },
      };
    }
  } else {
    enableCaching();
  }
  let stats = await getStatsProps();
  const host = getHost(context.req);
  const proto = getProto(context.req);
  const loadedLanguages = await loadLanguages();
  const translation = await loadTranslation({
    lang: isLanguage(context.locale) ? context.locale : "en",
    loadedLanguages,
  });

  const title = `${
    translation.messages.title ??
    loadedLanguages.en.title ??
    loadedLanguages.ru.title
  }${isPostTL ? "" : " (ex TL Legacy)"}`;
  const description =
    translation.messages.description ??
    loadedLanguages.en.description ??
    loadedLanguages.ru.description;

  const isNotXDefault = context.locale !== "default";

  const canonicalUrl = `https://${process.env.TL_CANONICAL ?? host}/${
    isNotXDefault ? `${translation.lang}` : ""
  }`;

  const dmca =
    "x-dmca" in context.req.headers && context.req.headers["x-dmca"] === "1";

  const props: Props = {
    title,
    description,
    stats,
    linkPrefix: process.env.TL_DOWNLOAD_LINKS_BASE || `${proto}://${host}`,
    translation,
    host,
    proto,
    canonicalUrl,
    dmca,
  };

  return {
    props,
  };
};
